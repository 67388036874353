var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"form form-checkout scale-label"},[(!_setup.transactionData)?_c('div',{staticClass:"form__message mb-0",attrs:{"data-role":"checkout-animation-body"}},[_c('p',[_vm._v("\n      "+_vm._s(_setup.t(
          'Checkout#Payment#CCAvenue Please validate your reservation and proceed to payment.'
        ))+"\n    ")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"form__body",attrs:{"data-role":"checkout-animation-body"}},[_c('div',{staticClass:"fieldset"},[_c('div',{staticClass:"error-row"},[(_setup.inlineBookingError)?_c(_setup.PaymentInlineError):_vm._e()],1)])]),_vm._v(" "),_c('div',{staticClass:"form__footer total row-total"},[_c(_setup.OrderTotalPrice),_vm._v(" "),(!_setup.transactionData)?[_c(_setup.CheckboxPrivacyPolicy,{directives:[{name:"show",rawName:"v-show",value:(!_setup.inlineBookingError),expression:"!inlineBookingError"}],on:{"change":function($event){_setup.isPolicyAccepted = $event}}}),_vm._v(" "),_c('v-btn',{staticClass:"text-none mt-4",attrs:{"color":"primary","disabled":_setup.isSubmitDisabled,"ripple":false,"block":"","tile":"","x-large":""},on:{"click":_setup.submit}},[_c('span',[_vm._v(_vm._s(_setup.btnText)+"  ")]),_vm._v(" "),(_setup.reservationLoading)?_c(_setup.Loader,{attrs:{"size":30,"absolute":false}}):_vm._e()],1)]:_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"payment-containers mt-4"},[(_vm.paymentProvider === 'juspay')?_c('div',{style:({
        height: _setup.transactionData ? '700px' : '0px'
      }),attrs:{"id":"juspayContainer"}}):_vm._e(),_vm._v(" "),(_vm.paymentProvider === 'payrails')?_c('div',{style:({
        display: _setup.transactionData ? 'block' : 'none',
        height: 'auto'
      }),attrs:{"id":"payrails-payment-container"}}):_vm._e()])])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }